import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import messages from './SettingsDocumentForm.intl';

const SettingsDocumentFormNFE = props => {
    const {
        intl,
        handleFormSubmit,
        moduleSettings,
        documentType,
        title,
        selectedNfeEnvironment,
        handleSelectedNfeEnvironment,
    } = props;

    const nfeEnvironments = [
        { value: 1, label: 'Produção' },
        { value: 2, label: 'Homologação' },
    ];

    return (
        <AvForm
            onValidSubmit={(event, values) =>
                handleFormSubmit(event, {
                    ...values,
                    documentType: documentType,
                    hideMonitiring: false,
                })
            }
        >
            <div>
                <h5 className='title-area-form'>
                    {intl.formatMessage(messages.monitoringModule, {
                        entity: title,
                    })}
                </h5>
                <Row style={{ maxHeight: 70 }}>
                    <Col md={6}>
                        <FormGroup check className='required-field'>
                            <AvField
                                label={intl.formatMessage(
                                    messages.enableMonitoring,
                                    { entity: title },
                                )}
                                type='checkbox'
                                name='enableMonitoring'
                                id='enableMonitoring'
                                value={moduleSettings?.enableMonitoring}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <AvField
                            label={intl.formatMessage(
                                messages.receiveDocumentsOnlyAfterDate,
                            )}
                            type='date'
                            name='receiveDocumentsOnlyAfterDate'
                            id='receiveDocumentsOnlyAfterDate'
                            value={
                                moduleSettings?.receiveDocumentsOnlyAfterDate?.split(
                                    'T',
                                )[0]
                            }
                            validate={{ datetime: true }}
                        />
                    </Col>
                </Row>
            </div>
            <h5 className='title-area-form'>{title}</h5>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.documentSkipValidation,
                            )}
                            type='checkbox'
                            name='skipValidationStepWhenAllFieldsAreValid'
                            id='skipValidationStepWhenAllFieldsAreValid'
                            value={
                                moduleSettings?.skipValidationStepWhenAllFieldsAreValid
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <label>
                        {intl.formatMessage(messages.environmentIdentification)}
                    </label>
                    <Select
                        id='environment'
                        name='environment'
                        className='evt-select'
                        options={nfeEnvironments}
                        placeholder={intl.formatMessage(
                            messages.selectPlaceholder,
                        )}
                        value={selectedNfeEnvironment}
                        onChange={handleSelectedNfeEnvironment}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.documentIsRequiredOrderNumber,
                            )}
                            type='checkbox'
                            name='isRequiredOrderNumberToSendDocuments'
                            id='isRequiredOrderNumberToSendDocuments'
                            value={
                                moduleSettings?.isRequiredOrderNumberToSendDocuments
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.doNotSendToIntegrationDocumentsThatNotBelogsToCompapy,
                            )}
                            type='checkbox'
                            name='doNotSendToIntegrationDocumentsThatNotBelogsToCompapy'
                            id='doNotSendToIntegrationDocumentsThatNotBelogsToCompapy'
                            value={
                                moduleSettings?.doNotSendToIntegrationDocumentsThatNotBelogsToCompapy
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.saveOriginalFile,
                            )}
                            type='checkbox'
                            name='saveOriginalFile'
                            id='saveOriginalFile'
                            value={moduleSettings?.saveOriginalFile}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.notAllowDuplicateNFe,
                            )}
                            type='checkbox'
                            name='blockInvoicesWithSameNumber'
                            id='blockInvoicesWithSameNumber'
                            value={moduleSettings?.blockInvoicesWithSameNumber}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.automaticAwareAllNfes,
                            )}
                            type='checkbox'
                            name='AutomaticAwareAllNfes'
                            id='AutomaticAwareAllNfes'
                            value={moduleSettings?.automaticAwareAllNfes}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup check className='required-field'>
                        <AvField
                            label={intl.formatMessage(
                                messages.allowSendEmailToSupplier,
                            )}
                            type='checkbox'
                            name='allowSendEmailToSupplier'
                            id='allowSendEmailToSupplier'
                            value={
                                moduleSettings?.allowSendEmailToSupplier
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <button
                        type='submit'
                        className='btn-submit'
                        disabled={props.loading}
                    >
                        {intl.formatMessage(messages.submitButton)}
                    </button>
                </Col>
            </Row>
        </AvForm>
    );
};

export default injectIntl(SettingsDocumentFormNFE);
