import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    notifyEmail: {
        id: 'settings.notifyEmail',
        defaultMessage:
            'Notificar o seguinte email quando usuário não cadastrado enviar um documento:',
    },
    documentIsRequiredOrderNumber: {
        id: 'settings.documentIsRequiredOrderNumber',
        defaultMessage:
            'Obrigar informação de número do pedido ao enviar documento.',
    },
    documentSkipValidation: {
        id: 'settings.documentSkipValidation',
        defaultMessage: 'Pular etapa de revisão caso o documento seja válido',
    },
    monitoringModule: {
        id: 'settings.monitoringModule',
        defaultMessage: 'Monitoramento de {entity}',
    },
    CNPJsToBeMonitored: {
        id: 'settings.CNPJsToBeMonitored',
        defaultMessage: 'CNPJs a serem monitorados',
    },
    enableMonitoring: {
        id: 'settings.EnableMonitoring',
        defaultMessage: 'Ativar monitoramento de {entity}',
    },
    enableMonitoringNfseNacional: {
        id: 'settings.EnableMonitoringNfseNacional',
        defaultMessage: 'Ativar monitoramento de NFSe Nacional',
    },
    allowAutomaticSupplierCreation: {
        id: 'settings.AllowAutomaticSupplierCreation',
        defaultMessage:
            'Permitir cadastro automático de fornecedor na entrada do xml no portal',
    },
    allowDocumentWithoutMapForSupplier: {
        id: 'settings.allowDocumentWithoutMapForSupplier',
        defaultMessage: 'Permitir entrada de documentos sem mapeamento.',
    },
    allowPdfAnnotation: {
        id: 'settings.allowPdfAnnotation',
        defaultMessage: 'Permitir leitura Pdf Annotation.',
    },
    allowedCategories: {
        id: 'settings.allowedCategories',
        defaultMessage:
            'Categorias permitidas para envio de documento sem pedidos',
    },
    allowIdentifySupplier: {
        id: 'settings.allowIdentifySupplier',
        defaultMessage:
            'Identificar automaticamente o fornecedor ao receber documentos do tipo NFSE via email.',
    },
    allowIdentifySupplierOther: {
        id: 'settings.allowIdentifySupplierOther',
        defaultMessage:
            'Identificar automaticamente o fornecedor ao receber documentos do tipo OUTROS via email.',
    },
    blockInvoicesWithSameNumber: {
        id: 'settings.blockInvoicesWithSameNumber',
        defaultMessage:
            'Não permitir entradas via integração/email de notas já existentes na base de dados',
    },
    thereAreCities: {
        id: 'settings.thereAreCities',
        defaultMessage:
            'Você tem {entity} prefeituras cadastradas. Cadastrar mais prefeituras?',
    },
    noCities: {
        id: 'settings.noCities',
        defaultMessage: 'Você não tem prefeituras cadastradas.',
    },
    registerCities: {
        id: 'settings.registerCities',
        defaultMessage: 'Cadastrar prefeituras',
    },
    saveOriginalFile: {
        id: 'settings.saveOriginalFile',
        defaultMessage: 'Salvar arquivo original enviado por e-mail',
    },
    notAllowDuplicateCteXml: {
        id: 'settings.notAllowDuplicateCteXml',
        defaultMessage:
            'Não permitir entrada de xml CTe já existentes na base de dados',
    },
    notAllowDuplicateNFe: {
        id: 'settings.notAllowDuplicateNFe',
        defaultMessage:
            'Não permitir entradas de notas já existentes na base de dados',
    },

    doNotSendToIntegrationDocumentsThatNotBelogsToCompapy: {
        id: 'settings.doNotSendToIntegrationDocumentsThatNotBelogsToCompapy',
        defaultMessage:
            'Não permitir o envio de documentos para integração de tomadores que não estão no cadastro de filiais',
    },
    automaticAwareAllNfes: {
        id: 'settings.AutomaticAwareAllNfes',
        defaultMessage: 'Manifestar ciência automaticamente para todas as NFes',
    },
    environmentIdentification: {
        id: 'settings.environmentIdentification',
        defaultMessage: 'Identificação de ambiente',
    },
    allowCanceledDocumentsAsEventDocumentType: {
        id: 'settings.allowCanceledDocumentsAsEventDocumentType',
        defaultMessage: 'Tratar cancelamento como evento',
    },
    receiveDocumentsOnlyAfterDate: {
        id: 'settings.receiveDocumentsOnlyAfterDate',
        defaultMessage: 'Receber somente documentos a partir da data',
    },
    enableMonitoringNacional: {
        id: 'settings.enableMonitoringNacional',
        defaultMessage: 'Ativar monitoramento Nacional',
    },
    blockInboundDocumentsIn24hours: {
        id: 'settings.blockInboundDocumentsIn24hours',
        defaultMessage:
            'Bloquear o envio de notas fiscais via tela ou email caso a cidade do fornecedor tenha integração (24 horas)',
    },
    associateBillsFromEmail: {
        id: 'settings.associateBillsFromEmail',
        defaultMessage:
            'Associar boletos que forem anexados no mesmo email de uma NFSe',
    },
    allowSendEmailToSupplier: {
        id: 'settings.AllowSendEmailToSupplier',
        defaultMessage:
            'Permitir fornecedor receber respostas do documento quando enviado por email',
    },
    blockNonPDFDocuments: {
        id: 'settings.blockNonPDFDocuments',
        defaultMessage: 'Bloquear documentos que não sejam do tipo PDF.',
    },
    hasIAMapping: {
        id: 'settings.hasIAMapping',
        defaultMessage: 'Mapeamento por IA',
    },
});

export default messages;
